<template>
<div>
    <v-snackbar v-model="snack" :timeout="3000" color="blue darken-2 top-down" top>
        <div class="f-12 text-center">{{message}}</div>
    </v-snackbar>
</div>
</template>

<script>
export default {
    data() {
        return {
            snack:false,
            message: "",
        }
    },
}
</script>

<template>
<div>
    <v-container>
        <h1 class="mb-3">支払方法の選択</h1>
        <p>設定を変更後「保存する」をクリックしてください</p>
        <div class="payment_box relative-center">
            <v-card class="pa-6 mb-6">
                <p>お客様がご利用できる支払方法を選択してください</p>
                <div class="inline-block">
                    <v-checkbox :label="payment.name" color="indigo" hide-details v-model="payment.check" v-for="payment in payments" :key="payment.name" class="ma-2"></v-checkbox>
                </div>
                <div class="f-09 f-red mt-6 text-left">※クレジットカード決済は各支払に対し、弊社の代行手数料4.0%が掛かります</div>
            </v-card>

            <v-card class="pa-6 mb-6">
                <h2 class="f-brown">振込先銀行口座の入力</h2>
                <p class="pb-6">お客様支払の振込先に指定する口座名を入力してください</p>
                <v-form ref="form">
                    <v-text-field v-model="bank" :rules="requredRules" label="金融機関名" outlined type="text" />
                    <v-text-field v-model="branch" :rules="requredRules" label="支店名" outlined type="text" />
                    <v-radio-group row v-model="type">
                        <v-radio :label="radios[n-1]" hide-details :value="n-1" color="indigo" v-for="n in 2" :key="n"></v-radio>
                    </v-radio-group>
                    <v-text-field v-model="number" :rules="bankNumRules" label="口座番号" outlined type="number" />
                    <v-text-field v-model="name" :rules="requredRules" label="口座名義" outlined type="text" />
                </v-form>
            </v-card>

        </div>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <Snackbar ref="snack" />
</div>
</template>

<script>
import Snackbar from "@/components/snackbar.vue"
import form from '@/mixins/form'
import axios from "axios"
export default {
    mixins: [form],
    data() {
        return {
           
            radios: ["普通", "当座"],
            bank: "",
            branch: "",
            type: 0,
            number: "",
            name: "",
        }
    },
    computed: {
        User() {
            return this.$store.getters['user/user']
        },
         Bank() {
            return this.$store.getters['user/bank']
        },
    },
    components: {
        Snackbar,
    },
    async mounted() {
      let payment = this.User.payment.split(',');
        for (let i = 0; i < payment.length; i++) {
                this.payments[i].check=JSON.parse(payment[i])
        }
        this.bank=this.Bank.bank
        this.branch=this.Bank.branch
        this.type=this.Bank.type
        this.number=this.Bank.number
         this.name=this.Bank.name
         console.log(this.Bank)
    },
    methods: {
        async submit() {
            if (this.payments[1].check && !this.$refs.form.validate()) {
                return false;
            }

            const data = {
                payment: (this.payments.map(item => item["check"])).join(','),
                bank: this.bank,
                branch: this.branch,
                type: this.type,
                number: this.number,
                name: this.name,
            }


            const response = await axios.post("/user/payment/save", data)
            if (response.data.ok) {
                await this.$store.dispatch("user/paymentSave", response.data);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            }else{
                alert("エラーが発生しました")
            }
    }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/payment.scss";
</style>
